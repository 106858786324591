/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { refetchApiInterval } from "../../shared/constants";
import useGlobalStore from "../../store/useGlobalStore";
import { getProjectDetailsData, getProjectEnergyActivitiesData, getProjectEnergyMetricsData, getProjectListData, getProjectNetworkData, getUsageHistoryDetailsData, useProjectAssetChargeActivitiesData } from "../api/project-asset-details.service";
export const useProjectListQuery = (queryParams, partyNumber) => {
    return useQuery({
        enabled: !!partyNumber,
        gcTime: 0,
        queryFn: () => {
            return getProjectListData(queryParams);
        },
        queryKey: ["projectList", queryParams],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
export const useProjectDetailsQuery = (id, queryParams, refetchApiData) => {
    return useQuery({
        gcTime: 0,
        queryFn: () => {
            return getProjectDetailsData(id, queryParams);
        },
        queryKey: ["projectDetails", queryParams, id, refetchApiData],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
export const useUsageHistoryDetailsQuery = ({ aggregation, assetId, assetSourceType, fetchHistoryData, id, refetchApiData, refreshTimestamp, startDate }) => {
    const setIsLoading = useGlobalStore((state) => state.setIsUsageHistoryLoading);
    return useQuery({
        enabled: fetchHistoryData,
        gcTime: 0,
        queryFn: async () => {
            setIsLoading(true);
            const response = await getUsageHistoryDetailsData(id, startDate, aggregation, assetSourceType, assetId);
            setIsLoading(false);
            if (!response) {
                throw new Error("No Data Available");
            }
            return response;
        },
        queryKey: ["UsageHistory", startDate, aggregation, id, assetSourceType, refreshTimestamp, refetchApiData],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
export const useProjectEnergyMetricsQuery = (id, startDate, refetchApiData) => {
    return useQuery({
        gcTime: 0,
        queryFn: async () => {
            return await getProjectEnergyMetricsData(id, startDate);
        },
        queryKey: ["EnergyMetrics", startDate, id, refetchApiData],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
export const useProjectNetworkQuery = (id, refetchApiData) => {
    return useQuery({
        gcTime: 0,
        queryFn: async () => {
            return await getProjectNetworkData(id);
        },
        queryKey: ["projectNetwork", id, refetchApiData],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
export const useProjectEnergyActivitiesQuery = (id, startDate, fetchHistoryData, refetchApiData) => {
    return useQuery({
        enabled: fetchHistoryData,
        gcTime: 0,
        queryFn: () => {
            return getProjectEnergyActivitiesData(id, startDate);
        },
        queryKey: ["EnergyActivities", startDate, id, refetchApiData],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
export const useProjectAssetChargeActivitiesQuery = (id, startDate, assetId, fetchHistoryData, refreshTimestamp, refetchApiData) => {
    const setIsLoading = useGlobalStore((state) => state.setIsLoading);
    return useQuery({
        enabled: fetchHistoryData,
        gcTime: 0,
        queryFn: async () => {
            setIsLoading(true);
            const response = await useProjectAssetChargeActivitiesData(id, startDate, assetId);
            setIsLoading(false);
            if (!response || response.length === 0) {
                throw new Error("No Data Available");
            }
            return response;
        },
        queryKey: ["AssetChargeActivities", startDate, id, refreshTimestamp, refetchApiData],
        refetchInterval: refetchApiInterval,
        retry: 1
    });
};
